/* ONLINE CHATBOX custom configuration goes here (www.olark.com/documentation) */
olark.configure("locale.welcome_title", "Click to Chat");
olark.configure("locale.chatting_title", "Live Help: Now Chatting");
olark.configure("locale.welcome_message", "Welcome, fill in the fields and start the chat or search in the section <a href='https://www.lordgunbicycles.co.uk/help/faq' target='_blank'>Frequently Asked Questions</a>");
olark.configure("locale.chat_input_text", "Type here and hit to chat");
olark.configure("locale.ended_chat_message", "This conversation has ended.");
olark.configure("locale.end_chat_button_text", "End chat");

/* OFFLINE CHATBOX custom configuration goes here (www.olark.com/documentation) */
olark.configure("locale.unavailable_title", "Need help?");
olark.configure("locale.away_message", "At this moment, the operators are not available. If you need any assistance please fill out the form in the <a href='https://www.lordgunbicycles.co.uk/help#contactUs' target='_blank'>help</a> section");
olark.configure("locale.name_input_text", "Type your Name");
olark.configure("locale.email_input_text", "Type your Email");
olark.configure("locale.phone_input_text", "Type your Phone");
olark.configure("locale.offline_note_message", "We are offline, send us a message");
olark.configure("locale.send_button_text", "Send");
olark.configure("locale.offline_note_sending_text", "Sending...");
olark.configure("locale.offline_note_thankyou_text", "Thank you for your message. We will get back to you as soon as we can.");
olark.configure("locale.offline_note_error_text", "You must complete all fields and specify a valid email address");

/* PRE-CHAT SURVEY custom configuration goes here (www.olark.com/documentation) */
olark.configure("locale.name_input_text", "Type your Name");
olark.configure("locale.email_input_text", "Type your Email");
olark.configure("locale.phone_input_text", "Type your Phone");
olark.configure("locale.introduction_error_text", "You must complete all fields and specify a valid email address.");
olark.configure("locale.introduction_messages", "Welcome, fill in the fields and start the chat or search in the section <a href='https://www.lordgunbicycles.co.uk/help/faq' target='_blank'>Frequently Asked Questions</a>");
olark.configure("locale.introduction_submit_button_text", "Start chat");
olark.configure("locale.prechat_survey_name_label", "Name");
olark.configure("locale.prechat_survey_name_placeholder", "Name");

/* CHAT RATINGS custom configuration goes here (www.olark.com/documentation) */
olark.configure('locale.feedback_survey_question_chat_text', 'How satisfied were you with this chat?');
olark.configure('locale.feedback_survey_question_operator_intelligence_text', 'How knowledgeable was the chat operator?');
olark.configure('locale.feedback_survey_question_operator_speed_text', 'How responsive was the chat operator?');
olark.configure('locale.feedback_survey_question_operator_attitude_text', 'How friendly was the chat operator?');
olark.configure('locale.feedback_survey_question_1_text', 'Question 1 of 5');
olark.configure('locale.feedback_survey_question_2_text', 'Question 2 of 5');
olark.configure('locale.feedback_survey_question_3_text', 'Question 3 of 5');
olark.configure('locale.feedback_survey_question_4_text', 'Question 4 of 5');
olark.configure('locale.feedback_survey_question_5_text', 'Question 5 of 5');
olark.configure('locale.feedback_survey_question_chat_low', 'Not satisfied at all');
olark.configure('locale.feedback_survey_question_chat_high', 'Extremely satisfied');
olark.configure('locale.feedback_survey_question_operator_intelligence_low', 'Not knowledgeable at all');
olark.configure('locale.feedback_survey_question_operator_intelligence_high', 'Extremely knowledgeable');
olark.configure('locale.feedback_survey_question_operator_speed_low', 'Not at all responsive');
olark.configure('locale.feedback_survey_question_operator_speed_high', 'Extremely responsive');
olark.configure('locale.feedback_survey_question_operator_attitude_low', 'Not friendly at all');
olark.configure('locale.feedback_survey_question_operator_attitude_high', 'Extremely friendly');
olark.configure('locale.feedback_survey_question_additional_feedback_text', 'Additional feedback');
olark.configure('locale.feedback_survey_button_next', 'Next');
olark.configure('locale.feedback_survey_button_submitting', 'Submitting');
olark.configure('locale.feedback_survey_button_finish', 'Finish');
olark.configure('locale.feedback_survey_end_message', 'Thank you for your feedback');
olark.configure('locale.feedback_survey_submission_error_message', 'There was an error submitting your answer, please try again.');
olark.configure('locale.feedback_survey_begin_button_text', 'Rate chat');
olark.configure('locale.feedback_survey_complete_button_text', 'Done');
olark.configure('locale.feedback_survey_cancel_text', 'Cancel');
olark.configure('locale.restart_chat_online_button_text', 'Start Chatting');
olark.configure('locale.restart_chat_offline_button_text', 'Email');

/* SEND TRANSCRIPTS custom configuration goes here */
olark.configure('locale.send_transcript_title_text', 'Send transcription');
olark.configure('locale.send_transcript_begin_button_text', 'Send transcription');
olark.configure('locale.send_transcript_complete_button_text', 'Transcript sent!');
olark.configure('locale.send_transcript_error_text', 'There was an error sending this transcript');
olark.configure('locale.send_transcript_cancel_text', 'Cancel');
olark.configure('locale.send_transcript_placeholder', 'Email');

/* OPERATOR custom configuration goes here */
olark.configure("locale.operator_is_typing_text", "is typing...");
olark.configure("locale.operator_has_stopped_typing_text", "has stopped typing");

olark.configure("locale.disabled_input_text_when_convo_has_ended", "Chat ended, refresh for new chat");
olark.configure("locale.disabled_panel_text_when_convo_has_ended", "This conversation has ended, but all you need to do is refresh the page to start a new one!");